<!--
 * @Author: 张博洋
 * @Date: 2021-11-01 16:59:27
 * @LastEditTime: 2021-11-11 11:54:08
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/check/result.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div style="overflow-y:auto">
    <div class="banner">
      <img :src="result.resultPic"
           alt="">
    </div>
    <div class="result">
      <div class="title">{{result.result}}</div>
      <div class="score-bar">
        <img src="../../static/img/position.png"
             alt="">
      </div>
      <div class="tip">
        提示：分值越高，{{$route.query.questionnaireId == '1' ? '焦虑' : '抑郁'}}程度越严重
      </div>
      <div class="anl">
        <h1>结果分析</h1>
        <p v-html="result.detail"></p>
      </div>

    </div>

    <p class="mtip">
      特别提示：{{result.illustrate}}
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      result: Object.create(null)
    }
  },
  created () {
    console.log(this.$route.query)
    if (this.$route.query.questionnaireId == '1') {
      this.$.setTitle('焦虑测评')
    } else if (this.$route.query.questionnaireId == '2') {
      this.$.setTitle('抑郁测评')
    }
    this.getResult()
  },
  mounted () {

  },
  methods: {
    getResult () {
      this.$axios({
        type: 'post',
        url: 'question/querySingleQAResult',
        data: {
          questionUserRefId: this.$route.query.questionUserRefId
        },
        elseData: {
          loading: true
        },
        _self: this
      }).then(res => {
        console.log(res)
        this.result = res.d
        $('.score-bar > img').animate({
          left: this.result.totalScore + '%',
        }, 800)
      })
    }
  }
}
</script>
<style lang="scss">
.anl {
  margin: 38px 16px 0;
  p {
    b {
      font-weight: bold;
    }
  }
}
</style>
<style lang="scss" scoped>
.banner {
  img {
    width: 100%;
  }
}
.result {
  background: #fff;
  margin: -64px 8px 0;
  border-radius: 8px;
  padding: 24px 0;
  .title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff8200;
    padding: 0 16px;
  }
  .score {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    span {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .score-bar {
    margin: 10px 16px 0;
    background: url('../../static/img/score-bar.png') no-repeat;
    height: 46px;
    background-size: 100%;
    background-position: 0 100%;
    position: relative;
    img {
      width: 12px;
      height: 12px;
      position: absolute;
      bottom: -4px;
    }
  }
  .tip {
    margin: 24px 16px 0;
    height: 44px;
    background: #ffebeb;
    border-radius: 8px;
    line-height: 44px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff4d4f;
    padding: 0 16px;
  }
  .anl {
    margin: 38px 16px 0;
    h1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    p {
      margin-top: 25px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      white-space: pre-line;
    }
  }
}
.mtip {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding: 20px 24px;
}
</style>